import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { Quote } from '../../model/quote/quote.model';
import { AlCalculationsComponent } from '../al-calculations/al-calculations.component';
import { AlQuoteService } from '../al-quote.service';
import { FinancialDetails } from '../../model/quote/financial_details.model';
import { AlCalculationsDetails } from '../al-calculations/al-calculations.model';
import { PolicyHolderAddress } from '../../model/quote/policy_holder_address.model';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-al-indicative-quote',
  templateUrl: './al-indicative-quote.component.html',
  styleUrls: ['../../layout/layout.component.css']
})
export class AlIndicativeQuoteComponent implements OnInit {
  @Input() quoteDetails: Quote;
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  calculationDetails = new AlCalculationsComponent();
  @Output() ProceedWithQuoteBtn = new EventEmitter();
  Calculations = new AlCalculationsDetails();
  currDate = new Date();
  maxPolicyDate;

  @Input()
  set staticData(value: any) {
    if (value) {
      // IPT/Administrative Fee
      this.Calculations.ipt_charges = Number(value.peliwica_ipt_commission);
      this.Calculations.administrative_charges = Number(value.broker_uw_fees);
    }
  }

  constructor(private modalService: NgbModal, private quoteService: AlQuoteService, private _snackBar: MatSnackBar,
    private sessionSt: LocalStorageService, private el: ElementRef, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let maxDate = new Date().setDate(new Date().getDate() + 30);
        this.maxPolicyDate = new Date(maxDate);

  }

  quote;
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    this.quote.company_id = this.sessionSt.retrieve('al_broker_company_id');
    this.quote.quote_versions[0].company_id = this.sessionSt.retrieve('al_broker_company_id');

  }

  /*
  * HELP TEXT POPUP
  */
  helptext = '';
  helpTextPopup(content, text) {
    this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * ANIMAL ADDRESS CHANGE
  */
 onAnimalAddressChange(event) {
  if(event.value == 1) {
    this.quote.quote_versions[0].animal_address = new PolicyHolderAddress();
  }
}

  /*
   * POSTCODE LOOKUP
   */
  addresses;
  postcodeLookup(event) {
    let postcode = event.target.value;
    this.quoteService.getAddressPostcodeLookup(postcode)
      .subscribe(
        (response: any) => {
          this.addresses = response.addresses;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  onSelectAddress(address) {
    this.quote.quote_versions[0].policy_holder.address.address1 = address.line_1;
    this.quote.quote_versions[0].policy_holder.address.address2 = address.line_2;
    this.quote.quote_versions[0].policy_holder.address.city = address.town_or_city;
    this.quote.quote_versions[0].policy_holder.address.country = address.county;
  }
  onSelectAnimalAddress(address) {
    this.quote.quote_versions[0].animal_address.address1 = address.line_1;
    this.quote.quote_versions[0].animal_address.address2 = address.line_2;
    this.quote.quote_versions[0].animal_address.city = address.town_or_city;
    this.quote.quote_versions[0].animal_address.country = address.county;
  }
  /*
  * On Public Liability Change
  */
  onPublicLiabilityChange(event) {
    if (event.value == 2) {
      this.quote.quote_versions[0].alpaca_trailer_cover = null;
    }
  }
  /*
  * SAVE QUOTE
  */
  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
          if (invalidControl) {
            invalidControl.focus();
            invalidControl.scrollIntoView({ behavior: 'smooth' });
          }
          break;
        }
      }

      return false;
    }
    let data: any = '';
    this.quote.quote_versions[0].quote_page_index = 0;

    this.quote.quote_versions[0].policy_start_date = formatDate(this.quote.quote_versions[0].policy_start_date, 'yyyy-MM-dd', 'en-US');
    if (this.quote.quote_versions[0].retroactive_date) {
      this.quote.quote_versions[0].retroactive_date = formatDate(this.quote.quote_versions[0].retroactive_date, 'yyyy-MM-dd', 'en-US');
    }

    if (!this.route.snapshot.params.quote_id && !this.quote.quote_versions[0].financial_details.id) {
      this.quote.quote_versions[0].financial_details = new FinancialDetails();
      this.quote.quote_versions[0].financial_details.ipt_fee = this.Calculations.ipt_charges;
      this.quote.quote_versions[0].financial_details.ipt_value = this.Calculations.ipt_amount;
      this.quote.quote_versions[0].financial_details.uw_fee = this.Calculations.administrative_charges;
      this.quote.quote_versions[0].financial_details.fees = this.Calculations.administrative_charges;
    }
    this.quote.calculations = this.Calculations;
    if (this.quote.quote_versions[0].alpaca_public_liability_cover != 1) {

      this.quote.quote_versions[0].alpaca_trailer_cover = 0;
    }
  
    //quote.quote_versions[0].

    let quote_temp = this.quote;
    data = {
      quote_data: this.quote
    };

    this.quoteService.saveQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quote = response.result.quote_data;
            this.quote.calculations = quote_temp.Calculations;
            this.quoteDetailsUpdated.emit(this.quote);
            this.ProceedWithQuoteBtn.emit();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          }

        });

  }
}
