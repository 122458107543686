import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Quote } from '../../model/quote/quote.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlCalculationsDetails } from '../al-calculations/al-calculations.model';
import { AlQuoteService } from '../al-quote.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-al-quotation',
  templateUrl: './al-quotation.component.html',
  styleUrls: ['../../layout/layout.component.css']
})
export class AlQuotationComponent implements OnInit {
  @Output() BackToSOFDetails = new EventEmitter();
  @Output() proceedToPayment = new EventEmitter();
  @Output() quoteDetailsUpdated = new EventEmitter<Quote>();
  @Input() quoteDetails: Quote;
  Calculations = new AlCalculationsDetails();
  client_website_link: string = '';
  alpacaRates;
  alpacaLiabiities = [];
  alpacaAllLiabiities = [];
  @Input()
  set staticData(value: any) {
    if (value) {

      // Alpaca Rates
      this.alpacaRates = value.alpaca_rates;

      // Alapca Liabiity
      this.alpacaAllLiabiities = value.alpaca_liability;
      value.alpaca_liability.forEach((res) => {
        res.is_checked = false;
      })
      this.alpacaLiabiities = value.alpaca_liability.filter(x => x.indeminity_limit == '1 Million');
    }
  }

  constructor(private quoteService: AlQuoteService, private _snackBar: MatSnackBar) { }

  quote;
  ngOnInit(): void {
    this.quote = this.quoteDetails;
    this.client_website_link = environment.CLIENT_WEBSITE_LINK;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
    // GET STATIC DATA
    if (!this.alpacaRates) {
      this.quoteService.getStaticData()
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.alpacaRates = response.result.alpaca_rates;
              // Alapca Liabiity
              this.alpacaAllLiabiities = response.result.alpaca_liability;
              response.result.alpaca_liability.forEach((res) => {
                res.is_checked = false;
              })
              this.alpacaLiabiities = response.result.alpaca_liability.filter(x => x.indeminity_limit == '1 Million');
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
    this.setFinancialDetails();
  }

  setFinancialDetails() {
    // Alpaca Rates
    if (this.quote.quote_versions[0].alpaca_theft_cover == 1) {
      if (this.alpacaRates) {
        let rates = this.alpacaRates.filter(x => x.type == 1);
        if (rates.length > 0) {
          this.Calculations.age_7_90_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.cria_rate);
          this.Calculations.age_7_90_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.alpaca_7dto90d) * Number(rates[0].value);
          this.Calculations.age_7_90_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_7dto90d);
        }
      }

      this.Calculations.age_above_90_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.alpaca_rate);
      this.Calculations.age_above_90_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured);
      this.Calculations.age_above_90_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_91dto12y);

      this.Calculations.age_13_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.age_13_rate);
      this.Calculations.age_13_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured_13y);
      this.Calculations.age_13_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_13y);

      this.Calculations.age_14_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.age_14_rate);
      this.Calculations.age_14_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.sum_insured_14y);
      this.Calculations.age_14_total_count = Number(this.quote.quote_versions[0].about_alpaca.alpaca_14y);


      this.Calculations.male_economic_slaughter_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.economic_slaughter_rate);
      this.Calculations.male_economic_slaughter_sum_insured = Number(this.quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured);

      this.Calculations.life_saving_surgical_fees = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.life_saving_rate);
      this.Calculations.veterinary_fees = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.veterinary_rate);
    }

    // Public Liability
    if (this.quote.quote_versions[0].alpaca_public_liability_cover == 1) {
      //Acrage
      if (this.quote.quote_versions[0].alpaca_liability) {
        this.Calculations.acrage = this.quote.quote_versions[0].alpaca_liability.acerage;
      }

      // Smallholding Activities
      this.Calculations.alpaca_experience_minimum_premium = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_alpaca_premium);
      this.Calculations.alpaca_excess_acrage = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_excess_acerage);
      this.Calculations.visit_insured_premises = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.visit_insured_premises_premium);
      this.Calculations.visit_to_extrenal_venues = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.visit_to_external_premises_premium);
      this.Calculations.promoting_selling_related_products = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.promoting_and_selling_premium);
      this.Calculations.trade_stand = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.visit_to_trade_stand_premium);

      // Liability Limits
      this.Calculations.basic_liabilty_minimum_premium = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.minimum_basic_liability);
      this.setLiabilityLimitValues();

      // Claim
      this.Calculations.claim_loading_percentage = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.claim_loading_percentage);

    }

    //Trailer
    if (this.quote.quote_versions[0].alpaca_trailer_cover == 1) {
      this.Calculations.trailer_rate = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.trailer_rate);
      this.Calculations.trailer_minimum_premium = Number(this.quote.quote_versions[0].financial_details.alpaca_financials.trailer_minimum_basic_premium);
      if (this.quote.quote_versions[0].trailer_cover) {
        this.Calculations.trailer_sum_insured = Number(this.quote.quote_versions[0].trailer_cover.trailer_insurance_cover);
      }
    }

    this.Calculations.ipt_charges = Number(this.quote.quote_versions[0].financial_details.ipt_fee);
    this.Calculations.ipt_amount = Number(this.quote.quote_versions[0].financial_details.ipt_value);
    this.Calculations.administrative_charges = Number(this.quote.quote_versions[0].financial_details.uw_fee);
    this.Calculations.calculate_data(this.quote);
  }

  /*
 * Set Liability Limit Financials
 */
  setLiabilityLimitValues() {
    if (this.quote.quote_versions[0].alpaca_liability) {
      switch (this.quote.quote_versions[0].alpaca_liability.public_liability_cover) {
        case 1:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '1 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '51f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '57f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
        case 2:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '2 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '61f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '67f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
        case 5:
          this.alpacaLiabiities = this.alpacaAllLiabiities.filter(x => x.indeminity_limit == '5 Million');
          this.alpacaLiabiities.forEach((liability) => {
            if (liability.id == '71f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.basic_liabilty_minimum_premium = Number(liability.minimum_basic_premium);
              this.Calculations.basic_liabilty_excess_acrage = Number(liability.excess_acerage);
            }
            if (liability.id == '77f635f8-c5c6-41ad-a75a-463f82b73a0e') {
              this.Calculations.employer_liability = Number(liability.minimum_basic_premium);
            }
          });
          break;
      }
    }
  }

  /*
  * BACK TO SOF
  */
  backToSOFTab() {
    this.BackToSOFDetails.emit();
  }

  /*
  * Save Quote
  */
  saveQuote() {

  }

  /*
  * Convert to Policy
  */
  convertToPolicy() {
    let data: any = '';
    data = {
      quote_id: this.quote.quote_versions[0].id,
      policy_ref_number: this.quote.quote_versions[0].policy_ref_number
    };
    this.quoteService.convertToPolicy(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quote = response.result;
            this.quoteDetailsUpdated.emit(this.quote);
            this.proceedToPayment.emit();
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          }
        });

  }
}
