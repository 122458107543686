import { Component, OnInit } from '@angular/core';
import { AlQuoteService } from '../al-quote/al-quote.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  logo_url: string = '';
  client_url: string = '';
  constructor(private quoteService: AlQuoteService) { }

  ngOnInit(): void {
    // Get Broker data
    this.quoteService.getLiveStockLogo()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.logo_url = environment.imageURL + response.result.logo_path;
          } 
        });
        this.client_url = environment.CLIENT_WEBSITE_LINK;
  }
}
