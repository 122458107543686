import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Quote } from '../model/quote/quote.model';
import { AlQuoteService } from './al-quote.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-al-quote',
  templateUrl: './al-quote.component.html',
  styles: [
  ]
})
export class AlQuoteComponent implements OnInit {
  quoteDetailsForChildTemplates = new Quote();
  selectedIndex: number = 0;
  quote_tab: boolean = true;
  quote_details_tab: boolean = false;
  full_details_tab: boolean = false;
  statement_of_facts_tab: boolean = false;
  quotation_tab: boolean = false;
  polciy_payment_tab: boolean = false;
  staticData;

  constructor(private quoteService: AlQuoteService, private route: ActivatedRoute, private router: Router, private sessionSt: LocalStorageService) {
    if (this.route.snapshot.params.index) {
      this.quote_tab = false;
      this.QuoteDetailsPage = true;
      this.selectedIndex = 4;
    }

  }

  ngOnInit(): void {
    this.sessionSt.store('al_broker_company_id', environment.HHID);
    // GET STATIC DATA
    this.quoteService.getStaticData()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.staticData = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // GET QUOTE DETAILS
    if (this.route.snapshot.params.quote_id) {

      this.quoteService.getQuoteDetails(this.route.snapshot.params.quote_id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.quoteDetailsForChildTemplates.quote_versions[0] = response.result;
              this.quoteDetailsForChildTemplates.id = response.result.quote_meta_id;
              if (response.result.is_policy == 1) {
                this.router.navigate(['/alpaca/quote-confirmation']);
              }

              this.QuoteDetailsPage = true;
              //this.quote_details_tab = false;
              // this.full_details_tab = true;
              // this.statement_of_facts_tab = true;
              // this.quotation_tab = true;
              // this.polciy_payment_tab = true;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  /*
  * Activate Indicative Quote Tab
  */
  activateIndicativeQuoteTab() {
    this.selectedIndex = 0;
  }
  /*
  * Activate Indicative Quote Detais Page
  */
  QuoteDetailsPage: boolean = false;
  activateIndicativeQuoteDetailsPage() {
    this.QuoteDetailsPage = true;
    this.quote_details_tab = true;
    this.selectedIndex = 1;
  }

  /*
  * Activate Full Details Page
  */
  activateFullDetailsTab() {
    this.selectedIndex = 2;
    this.full_details_tab = true;
  }
  /*
  * Activate SOF Page
  */
  activateSOFTab() {
    this.selectedIndex = 3;
    this.statement_of_facts_tab = true;
  }
  /*
  * Activate Quotation Tab
  */
  activateQuotationTab() {
    this.selectedIndex = 4;
    this.quotation_tab = true;
    //if (!this.route.snapshot.params.quote_id) {
      this.quote_tab = false;
      this.quote_details_tab = false;
      this.full_details_tab = false;
      this.statement_of_facts_tab = false;
    //}
  }
  /*
  * Activate Policy & Payment Tab
  */
  activatePolicyPaymentTab() {
    this.selectedIndex = 5;
    this.polciy_payment_tab = true;
    //if (!this.route.snapshot.params.quote_id) {
      this.quote_tab = false;
      this.quote_details_tab = false;
      this.full_details_tab = false;
      this.statement_of_facts_tab = false;
      this.quotation_tab = false;
    //}
  }

  /*
  * Update Quote Object After Form Save
  */
  quoteDetailsUpdated(quoteDetailsForChildTemplates) {
    this.quoteDetailsForChildTemplates = null;
    this.quoteDetailsForChildTemplates = quoteDetailsForChildTemplates;

  }
}
