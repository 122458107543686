import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';


import {LayoutComponent} from './alpaca/layout/layout.component';
import {PreQualifierComponent} from './alpaca/al-quote/pre-qualifier/pre-qualifier.component';
import {AlQuoteComponent} from './alpaca/al-quote/al-quote.component';
import {AlQuoteConfirmationComponent} from './alpaca/al-quote-confirmation/al-quote-confirmation.component';

const appRoutes: Routes = [
  {
 
    path: '', component: LayoutComponent,
    children: [
      { path: 'pre-qualifier', component: PreQualifierComponent },
      { path: 'quote', component: AlQuoteComponent },
      { path: 'pre-qualifier/:quote_id', component: PreQualifierComponent },
      { path: 'quote/:quote_id', component: AlQuoteComponent },
      { path: 'policy-payment/:quote_id/:index', component: AlQuoteComponent },
      { path: 'quote-confirmation', component: AlQuoteConfirmationComponent },
      { path: '', component: PreQualifierComponent },
    ]
  }
  
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
