<div class="calculations_panel mt-5">
<mat-accordion class="calculationsPanel">
    <mat-expansion-panel *ngIf="quote.quote_versions[0].alpaca_theft_cover == 1">
        <mat-expansion-panel-header class="cal_header">
            <mat-panel-title>
                Alpaca Mortality
            </mat-panel-title>
        </mat-expansion-panel-header>
        <h5 class="mt-3">Alpaca Mortality</h5>
        <p>
            <b>Policy premium</b><br>&pound;{{calculationsDe.alpaca_moroality_premium}}
        </p>
        
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="quote.quote_versions[0].alpaca_public_liability_cover == 1">
        <mat-expansion-panel-header class="cal_header">
            <mat-panel-title>
                Public & Product Liability
            </mat-panel-title>
        </mat-expansion-panel-header>
        <h5 class="mt-3">Public & Product Liability</h5>
        <p>
            <b>Public Liability premium</b><br>&pound;{{calculationsDe.public_liability_premium}}
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="quote.quote_versions[0].alpaca_trailer_cover == 1">
        <mat-expansion-panel-header class="cal_header">
            <mat-panel-title>
                Trailer
            </mat-panel-title>
        </mat-expansion-panel-header>
        <h5 class="mt-3">Trailer</h5>
        <p>
            <b>Policy premium</b><br>&pound;{{calculationsDe.trailer_premium}}
        </p>
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header class="cal_header">
            <mat-panel-title>
                Totals
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mt-3">
            <b>Total Policy Premium</b><br>&pound;{{calculationsDe.total_premium}}
        </p>
        <p>
            <b>Insurance premium tax (12%)</b><br>&pound;{{calculationsDe.ipt_amount}}
        </p>
        <p>
            <b>Total premium</b><br>&pound;{{calculationsDe.total_premium_without_fee}}
        </p>
        <p>
            <b>UW Fees</b><br>&pound;{{calculationsDe.administrative_charges}}
        </p>
        <p>
            <b>Broker Admin Fees</b><br>&pound;{{calculationsDe.broker_admin_fee}}
        </p>
        <p>
            <b>Total cost of the policy</b><br>&pound;{{calculationsDe.total_gross_premium}}
        </p>
    </mat-expansion-panel>
</mat-accordion>
<div class="quote_ref">
    Quote Reference: {{quote.quote_versions[0].quote_ref_number}}
</div>
</div>