import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Quote } from '../../model/quote/quote.model';
import { AlCalculationsDetails } from '../al-calculations/al-calculations.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-al-policy-and-payment',
  templateUrl: './al-policy-and-payment.component.html',
  styleUrls: ['../../layout/layout.component.css']
})
export class AlPolicyAndPaymentComponent implements OnInit {
  @Output() BackToQuotationTab = new EventEmitter();
  @Input() quoteDetails: Quote;
  Calculations = new AlCalculationsDetails();
  client_website_link: string = '';

  constructor() { }

  quote;
  ngOnInit(): void {
    this.quote = this.quoteDetails;
    this.client_website_link = environment.CLIENT_WEBSITE_LINK;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteDetails;
  }

  /*
  * Back To Quotation Tab
  */
 backToQuotationTab() {
   this.BackToQuotationTab.emit();
 }

}
