import { QuoteVersions } from './quote_versions.model';
import { AlCalculationsDetails } from '../../al-quote/al-calculations/al-calculations.model';

export class Quote {
    id: string = '';
    quote_id: string = '';   
    company_name: string= '';
    trading_name: string= '';
    ern_number: string= '';
    public_liability: number= null;
    trailer_cover: number= null;
    sum_insured_text: string= '';
    non_life_saving_surgery: number= null;
    life_saving_surgery: number= null;
    vets_treatment: number= null;
    public_liability_limit: number= null;
    total_trailers: number= null;
    insured_value: number= null;
    animal_sex: string= '';
    animal_value: string= '';
    animal_date_purchase: string= '';
    microchip_no: string= '';
    animal_dob: string= '';
    animal_type: string= '';
    other_farm_animal: string= '';
    overall_sof: number= null;
    age_limit: number= null;
    animal_address: number= null;
    security: string= '';
    total_animals: number = 0;    






    company_id: string = '';     
  
    insurance_status_id: string = 'e216564f-552a-4e45-ae67-0a63b7074691';    
    status: number = 0;
    insurer:string = '';
    
    policy_cover_index: string = ''; 
    vet_fees_cover_id: string = '';
    slider_broker_comm: number = 0;
    company_co_pay_discount: number = 0.00;  
    is_full_terms_accepted:  number = 0;
    is_sof_accepted:  number = 0;
    
    full_class_of_use: string = '';
    full_policy_cover: string = '';
    full_vet_fee_cover: string = '';
    full_life_saving_surgery: string = '';
    full_vet_fee_excess: string = '';
    full_public_liability: string = '';
    full_personal_accident: string = '';
    bloodstock_text: string = '';
    full_company_co_pay_excess = false;
    saddlery_items: number = null;
    indicativePage: boolean = false;
    company_co_insurance: boolean = false;
    full_quote_section: boolean = true;
    hide_sections_for_blood_stock: boolean = false;
    quote_document = 'Choose file';
    LifeSaving7500: boolean = false;
    primaryCover: string = '';

    hideAEIOCal: boolean = false;

    

    quote_versions:Array<QuoteVersions> = [new QuoteVersions()];
    calculations = new AlCalculationsDetails();
    
}
