import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {DataTablesModule} from 'angular-datatables';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatSliderModule} from '@angular/material/slider';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatSelectModule} from '@angular/material/select'; 
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';


import { SharedService } from './services/shared.service';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptorService } from './services/interceptors/loader-interceptor.service';

import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatDatepickerModule } from '@angular/material/datepicker'; 
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment;
import {MatExpansionModule} from '@angular/material/expansion';
import { AgGridModule } from 'ag-grid-angular';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { DecimalNumbersDirective } from './directives/decimal-numbers.directive';
import { TitleCasePipe } from '@angular/common';
import { TitleCaseDirective } from './directives/title-case.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { NumbersDirective } from './directives/numbers.directive';

import { LoaderComponent } from './_layouts/loader/loader.component';
import { PreQualifierComponent } from './alpaca/al-quote/pre-qualifier/pre-qualifier.component';
import { LayoutComponent } from './alpaca/layout/layout.component';
import { AlQuoteComponent } from './alpaca/al-quote/al-quote.component';
import { AlIndicativeQuoteComponent } from './alpaca/al-quote/al-indicative-quote/al-indicative-quote.component';
import { AlFullDetailsComponent } from './alpaca/al-quote/al-full-details/al-full-details.component';
import { AlSofComponent } from './alpaca/al-quote/al-sof/al-sof.component';
import { AlQuotationComponent } from './alpaca/al-quote/al-quotation/al-quotation.component';
import { AlPolicyAndPaymentComponent } from './alpaca/al-quote/al-policy-and-payment/al-policy-and-payment.component';
import { AlCalculationsComponent } from './alpaca/al-quote/al-calculations/al-calculations.component';
import { AlIndicativeQuoteDetailsComponent } from './alpaca/al-quote/al-indicative-quote-details/al-indicative-quote-details.component';
import { AlQuoteConfirmationComponent } from './alpaca/al-quote-confirmation/al-quote-confirmation.component';


@NgModule({
  declarations: [
    AppComponent,
    DecimalNumbersDirective,
    TitleCaseDirective,
    UpperCaseDirective,
    NumbersDirective,
    LoaderComponent,

    PreQualifierComponent,
    LayoutComponent,
    AlQuoteComponent,
    AlIndicativeQuoteComponent,
    AlFullDetailsComponent,
    AlSofComponent,
    AlQuotationComponent,
    AlPolicyAndPaymentComponent,
    AlCalculationsComponent,
    AlIndicativeQuoteDetailsComponent,
    AlQuoteConfirmationComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    MatTabsModule,
    BrowserAnimationsModule,
    DataTablesModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatExpansionModule,
    AgGridModule.withComponents([]),
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgbModule
  ],
  providers: [SharedService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},TitleCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
