<mat-tab-group [(selectedIndex)]="selectedIndex">
    <mat-tab [disabled]="!quote_tab">
        <ng-template mat-tab-label>
            Quote
        </ng-template>
        <div class="card-body">
            <app-al-indicative-quote [quoteDetails]="quoteDetailsForChildTemplates"
            (quoteDetailsUpdated)="quoteDetailsUpdated($event)" [staticData]="staticData"
            (ProceedWithQuoteBtn)="activateIndicativeQuoteDetailsPage()"></app-al-indicative-quote>
        </div>
    </mat-tab>
    <mat-tab *ngIf='QuoteDetailsPage' [disabled]="!quote_details_tab">
        <ng-template mat-tab-label>
            Quote Other Details
        </ng-template>
        <div class="card-body">
            <app-al-indicative-quote-details [quoteDetails]="quoteDetailsForChildTemplates"
            (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
            (BackToIndicativeQuote)="activateIndicativeQuoteTab()"
            (ProceedWithFullDetails)="activateFullDetailsTab()" [staticData]="staticData"></app-al-indicative-quote-details>
        </div>
    </mat-tab> 
    <mat-tab [disabled]="!full_details_tab">
         <ng-template mat-tab-label>
            Full Details
        </ng-template>
        <div class="card-body">
            <app-al-full-details [quoteDetails]="quoteDetailsForChildTemplates"
            (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
            (BackToQuoteDetails)="activateIndicativeQuoteDetailsPage()"
            (ProceedWithSOFDetails)="activateSOFTab()" [staticData]="staticData"></app-al-full-details>
        </div>
    </mat-tab>
    <mat-tab [disabled]="!statement_of_facts_tab">
        <ng-template mat-tab-label>
            Statement Of Fact
        </ng-template>
        <div class="card-body">
            <app-al-sof [quoteDetails]="quoteDetailsForChildTemplates"
            (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
            (BackToFullDetails)="activateFullDetailsTab()"
            (proceedToQuotationTab)="activateQuotationTab()"></app-al-sof>
        </div>
    </mat-tab>

    <mat-tab [disabled]="!quotation_tab">
        <ng-template mat-tab-label>
            Quotation Details
        </ng-template>
        <div class="card-body">
            <app-al-quotation [quoteDetails]="quoteDetailsForChildTemplates"
            (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
             (BackToSOFDetails)="activateSOFTab()" [staticData]="staticData"
            (proceedToPayment)="activatePolicyPaymentTab()"></app-al-quotation>
        </div>
    </mat-tab>
    <mat-tab [disabled]="!polciy_payment_tab">
        <ng-template mat-tab-label>
            Policy & Payment Details
        </ng-template>
        <div class="card-body">
            <app-al-policy-and-payment [quoteDetails]="quoteDetailsForChildTemplates"
            (quoteDetailsUpdated)="quoteDetailsUpdated($event)"
             (BackToQuotationTab)="activateQuotationTab()"></app-al-policy-and-payment>
        </div>
    </mat-tab>
</mat-tab-group>