export const environment = {
  staging: true,
  production: false,
  apiURL: "https://staging.api.peliwica.easyukhosting.co.uk/api/",
  imageURL: "https://staging.api.peliwica.easyukhosting.co.uk/",
  ADDRESSLOOKUP_API_URL: "https://api.getAddress.io/find/",
  ADDRESSLOOKUP_API_KEY: "cIc_-chj3kiI0S4t6jyUFQ27891",
  EQUINE_ID: '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95',
  HHID: '265aefd6-6b28-4965-93ac-48d2410da346',
  CLIENT_WEBSITE_LINK: 'https://www.lrms.co.uk',
  ALPACA_ID: '6dd8c0bb-b933-4f28-bc70-b5cc47fafb95'
};