<div class="header mb-4">
    <div class="container">
    <div class="row">
        <div class="col-md-4">
            <a class="ml-2" [href]="client_url">
                <img [src]="logo_url" alt="company logo" width="180" class="img-fluid" *ngIf="logo_url">
                <img src="assets/images/lrms_logo.png" alt="company logo" width="161" class="mt-4" *ngIf="!logo_url">
            </a>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-5 pt-4 text-right">
            <a href="tel:01284735617" class="text-blue"><img src="assets/images/phone.jpg" alt="Phone" style="width: 22px;"> 01284 735617</a>  &nbsp;&nbsp;&nbsp; 
            <a href="mailto:info@lrms.co.uk" class="text-blue"><img src="assets/images/email.jpg" alt="Email" class="mr-2" style="width: 15px;"> info@lrms.co.uk</a> 
        </div>
    </div>
    </div>
</div>

<div class="container">
    <router-outlet></router-outlet>
</div>

<div class="alpacafooter">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-2 text-center">
                <p>Please contact us to discuss your requirements</p>
                <p>t: 01284 735617 &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; e: info@lrms.co.uk &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; {{client_url}}</p>
                <p class="f13">lrms is authorised and regulated by the FCA No: 303950</p>
                
                <p class="f13">
                This product is underwritten by Peliwica Insurance, Peliwica Insurance is a Lloyd’s cover holder, authorised and regulated by The Financial Conduct Authority (FCA): registered number 808026
                </p>
            </div>
        </div>
    </div>
</div>