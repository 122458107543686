import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-al-quote-confirmation',
  templateUrl: './al-quote-confirmation.component.html',
  styleUrls: ['../layout/layout.component.css']
})
export class AlQuoteConfirmationComponent implements OnInit {
  client_website_link: string = '';
  constructor() { }

  ngOnInit(): void {
    this.client_website_link = environment.CLIENT_WEBSITE_LINK;
  }

}
