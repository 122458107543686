import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  headers;
  UserType = 0;

  constructor(private httpClient: HttpClient, private sessionSt: LocalStorageService) { }

  ngOnInit(): void {

  }

  getUserType() {
    if (this.sessionSt.retrieve('company_role') == 'Staff') {
      this.UserType = 1;
    }
    if (this.sessionSt.retrieve('company_role') == 'Insurer') {
      this.UserType = 2;
    }
    if (this.sessionSt.retrieve('company_role') == 'Broker') {
      this.UserType = 3;
    }

    return this.UserType;
  }

  apiURL() {
    return environment.apiURL;
  }
  apiHeaders() {
    let login_token = '';
    let BrokerId = '';
    if (this.sessionSt.retrieve('login_token') != null) {
      login_token = this.sessionSt.retrieve('login_token');
    }
    if (this.sessionSt.retrieve('al_broker_company_id') != null) {
      BrokerId = this.sessionSt.retrieve('al_broker_company_id');
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;',
      'Authorization': '',
      'BrokerId': environment.HHID
    }
    );
    return headers;
  }
  // GET AJAX CALL
  getAPI(url): Observable<string> {
    const headers = this.apiHeaders();
    url = environment.apiURL + url;
    return this.httpClient.get<string>(url, { headers: headers }).pipe(
      retry(3),
      catchError(() => {
        return EMPTY;
      }), shareReplay()
    );
  }

  // GET AJAX CALL
  getOtherAPI(url) {
    return this.httpClient.get(url);
  }

  // POST AJAX CALL
  postAPI(url, data) {
    try {
      const headers = this.apiHeaders();
      url = environment.apiURL + url;
      return this.httpClient.post(url, JSON.stringify(data), { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }

  // POST FORM DATA AJAX CALL
  postFormData(url, formData) {
    try {
      let login_token = '';
    let BrokerId = '';
    if (this.sessionSt.retrieve('login_token') != null) {
      login_token = this.sessionSt.retrieve('login_token');
    }
    if (this.sessionSt.retrieve('al_broker_company_id') != null) {
      BrokerId = this.sessionSt.retrieve('al_broker_company_id');
    }
    const headers = new HttpHeaders({
      'Authorization': login_token,
      'BrokerId': BrokerId
    }
    );
      url = environment.apiURL + url;
      return this.httpClient.post(url, formData, { headers: headers });
    }
    catch (error) {
      console.error('Unable to post data', error);
    }
  }

  
  download(url: string): Observable<Blob> {
    url = environment.imageURL + url;
    return this.httpClient.get(url, {
      responseType: 'blob'
    })
  }

  postDownload(url, data) {
    let login_token = '';
    if (this.sessionSt.retrieve('login_token') != null) {
      login_token = this.sessionSt.retrieve('login_token');
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;',
      
      'Authorization': login_token
    });
   return this.httpClient.post(
    environment.apiURL + url, 
      data, 
      { headers : headers, responseType: 'blob'});
  }
  
}
