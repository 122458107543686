export class AboutAlpaca {
    id: string = '';
    quote_id: string = '';
    alpaca_7dto90d: number = null;
    sum_insured: number = null;
    alpaca_91dto12y: number = null;       
    economic_slaughter_required_for: number = null;       
    economic_slaughter_sum_insured: number = null;       
    does_life_saving_cover_required: number = null;       
    does_vet_treatments_covered: number = null;       
    does_economic_slaughter_required: number = null;       
}
