import { Injectable } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlQuoteService {

  constructor(private sharedService: SharedService,private sessionSt: LocalStorageService) { }

  // Get Agreed Questions
  getAgreedQues(company_id, type) {
    return this.sharedService.getAPI('staticdata/alpaca_agreed_questions/' + type);
  }

  // Get All Static Data
  getStaticData(rating_version=0) {
    return this.sharedService.getAPI('staticdata/get_alpaca_static_data/'+rating_version);
  }


  // Save Quote
  saveQuote(data){
    return this.sharedService.postAPI('alpaca/quote', data);
  }
  // POSTCODE LOOKUP
  getAddressPostcodeLookup(postcode) {
    return this.sharedService.getOtherAPI(environment.ADDRESSLOOKUP_API_URL + postcode + '?api-key=' + environment.ADDRESSLOOKUP_API_KEY + '&expand=true&fuzzy=true');
  }

  // Get StatementOFFact Data
  getStatementOfFacts(version) {
    return this.sharedService.getAPI('staticdata/alpaca_statement_of_fact/6dd8c0bb-b933-4f28-bc70-b5cc47fafb95/100/'+version);
  }

  // Get Quote Details By ID
  getQuoteDetails(quote_id) {
    return this.sharedService.getAPI('alpaca/quote/details/'+quote_id + '/0');
  }

  // Convert To Policy
  convertToPolicy(data) {
    return this.sharedService.postAPI('alpaca/quote/convert_to_policy', data);
  }

  // Save Quote Document
  saveQuoteDocument(formData) {
    return this.sharedService.postFormData('alpaca/quote/document', formData);
  }

  // Update Document Details
  updateDocument(data) {
    return this.sharedService.postAPI('alpaca/quote/document/edit', data);
  }

  // Delete Document Details
  deleteDocument(data) {
    return this.sharedService.postAPI('alpaca/quote/document/delete', data);
  }

  // Get Documents
  getQuoteDocuments(quote_id) {
    return this.sharedService.getAPI('alpaca/quote/documents/' + quote_id + '/1');
  }

   // Get Broker Logo
   getLiveStockLogo() {
    return this.sharedService.getAPI('company/configuration/logo/latest/' + environment.HHID +"/"+ environment.ALPACA_ID);
  }
}

