import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AlQuoteService } from '../al-quote.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-pre-qualifier',
  templateUrl: './pre-qualifier.component.html',
  styleUrls: ['../../layout/layout.component.css']
})
export class PreQualifierComponent implements OnInit {
  agreedQuestions = [];

  constructor(private quoteService: AlQuoteService, private route: ActivatedRoute, private router: Router,
    private sessionSt: LocalStorageService) { }

  ngOnInit(): void {
    // GET AGREED QUESTIONS  
      if (this.route.snapshot.params.quote_id) {
        this.quoteService.getQuoteDetails(this.route.snapshot.params.quote_id)
          .subscribe(
            (response: any) => {
              if (response.success) {
                this.getAgreedQuestions(response.result.agreed_question_version);
                if (response.result.is_policy == 1) {
                  this.router.navigate(['/quote-confirmation']);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        this.getAgreedQuestions(0)
      }
  }

  getAgreedQuestions(type) {
    this.quoteService.getAgreedQues(environment.HHID, type)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.agreedQuestions = response.result.agreed_questions;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  agreedBtnAction() { 
    this.sessionSt.store('al_broker_company_id', environment.HHID);
    if(this.route.snapshot.params.quote_id) {
      this.router.navigate(['/quote',this.route.snapshot.params.quote_id]);
    } else {
      this.router.navigate(['/quote']);
    }
    
  }
}
